/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import React from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"
import Img from "gatsby-image"

const EventCard = ({ item, className }) => {
  const eventType = item.node.Type.replace(/_|-/g, " ")
  return (
    <Link
      to={`/event/${item.node.slug}`}
      //sx={{ textDecoration: "none" }}
      sx={styles}
      className={`event ${className ? className : ""}`}
      aria-label={item.node.Title}
    >
      <Box
        // sx={{ position: "relative" }}
        className={`event-${item.node.Type} eventImage`}
      >
        <Img
          fluid={item.node.Img.localFile.childImageSharp.fluid}
          alt={item.node.Img_alt}
          // sx={{
          //   //width: "230px",
          //   height: "400px",
          //   // position: "absolute",
          //   // // top: "-16.6%", //-50px
          //   // // left: "20%", //60px
          //   // // zIndex: 5,
          // }}
        />
        <Box
          // sx={{
          //   width: "50px",
          //   height: "50px",
          //   background: "white",
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   position: "absolute",
          //   zIndex: 4,
          //   bottom: 0,
          // }}
          className="arrow"
        >
          <FaArrowRight
          // sx={{
          //   position: "relative",

          //   color: "accent",

          //   fontSize: 5,
          // }}
          />
        </Box>
      </Box>
      <Box
        // sx={{
        //   px: 1,
        //   "& h1": {
        //     fontSize: 3,
        //     mt: 2,
        //     mb: 0,
        //     fontWeight: "700",
        //   },
        //   "& h2": {
        //     fontSize: 2,
        //     mt: 1,
        //     mb: 1,
        //     color: "accent",
        //     fontWeight: "400",
        //   },
        //   "& h3": {
        //     fontSize: 2,
        //     mt: 1,
        //     mb: 1,
        //     color: "muted",
        //     fontWeight: "400",
        //   },
        //   "& ul": {
        //     pl: 0,
        //     mt: 0,
        //     listStyle: "none",
        //     color: "muted",
        //   },
        //   "& li::before": {
        //     content: '"• "',
        //     color: "accent",
        //   },
        // }}
        className="eventDetails"
      >
        <h1>{item.node.Title}</h1>
        <h2>{eventType}</h2>
        <h3>{item.node.dateFormatted}</h3>
        {item.node.Time && (
          <ul>
            <li>{item.node.Time}</li>
          </ul>
        )}
      </Box>
    </Link>
  )
}
export default EventCard

const styles = {
  "&.event": {
    textDecoration: "none",
    mr: 2,
    "& .eventImage": {
      position: "relative",
      overflow: "hidden",
      height: ["auto", "400px"],
      ".gatsby-image-wrapper": {
        objectFit: "cover",
        maxWidth: "100%",
        width: ["100%", "auto", "300px"],
        height: ["220px", "400px"],
        transition: "all .3s ease",
      },
    },
    "& .arrow": {
      width: "50px",
      height: "50px",
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 4,
      bottom: "0",
      transition: "background .3s ease",
      "& svg": {
        position: "relative",
        color: "accent",
        fontSize: 5,
      },
    },
    "& .eventDetails": {
      transition: "background .3s ease",
      "& h1": {
        color: "black",
        fontSize: 3,
        mt: 1,
        mb: 0,
        fontWeight: "700",
      },
      "& h2": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "muted",
        fontWeight: "400",
      },
      "& h3": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "accent",
        fontWeight: "400",
      },
      "& ul": {
        pl: 0,
        mt: 0,
        listStyle: "none",
        color: "muted",
      },
      "& li::before": {
        content: '"• "',
        color: "accent",
      },
    },
    //event hover
    "&:hover": {
      "& .eventImage": {
        ".gatsby-image-wrapper": {
          transform: "scale(1.2)",
        },
        "& .arrow": {
          background: "black",
        },
      },
    },
  },
  //past events should be greyscale
  "&.event.past": {
    "& .eventImage": {
      ".gatsby-image-wrapper": {
        filter: "grayscale(1)",
      },
    },
  },
}
