import { Box } from "@theme-ui/components"
import React from "react"

const EventGridFuture = props => {
  return (
    <Box>
      <p>Event Grid Future component all events after {props.today}.</p>
    </Box>
  )
}

export default EventGridFuture
